import {
  FooterContainer,
  FooterLeft,
  FooterMid,
  FooterRight,
} from './Footer.styles';
import '@aws-amplify/ui-react';
import { StyledFooterText, StyledText } from '../../app.styles';
import { Link, useHref } from 'react-router-dom';
import { ROUTES } from '../../AppRoutes';
import { StyledTextAnchor } from '../Menu/Menu.styles';

export const Footer = () => {
  const footerText = {
    left: {
      leftText: null,
    },
    middle: {
      middleText: null,
    },
    right: {
      header: null,
    },
  };

  return (
    <>
      <FooterContainer>
        <FooterLeft>
          {footerText.left.header && (
            <StyledFooterText
              props={{ color: 'white', background: `var('--blue')` }}
            >
              Left Section
            </StyledFooterText>
          )}
        </FooterLeft>

        <FooterMid>
          {footerText.middle.header && (
            <StyledFooterText
              props={{ color: 'white', background: `var('--blue')` }}
            >
              <StyledText> {footerText.middle.header} </StyledText>
            </StyledFooterText>
          )}
        </FooterMid>

        <FooterRight>
          <StyledTextAnchor href={ROUTES.EXTERNAL.PRIVACY_POLICY}>
            <StyledFooterText color={'white'} background={`var('--blue')`}>
              Privacy Policy
            </StyledFooterText>
          </StyledTextAnchor>
        </FooterRight>
      </FooterContainer>
    </>
  );
};
