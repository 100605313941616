import isEmpty from 'lodash/isEmpty';

export const handleResetPasswordErrors = (error, errors, setErrors) => {
  const errEmail = [];
  const errPassword = [];
  const errCode = [];
  const errOther = [];

  if (error.includes('USERNAME')) {
    if (error.includes(errorResponses.email.one)) {
      errEmail.push(errorResponses.email.oneError);
    }
  }
  if (error.includes('username')) {
    if (error.includes(errorResponses.email.two)) {
      errEmail.push(errorResponses.email.twoError);
    }
  }
  if (error.includes('User')) {
    if (error.includes(errorResponses.email.three)) {
      errEmail.push(errorResponses.email.threeError);
    }
    if (error.includes(errorResponses.email.four)) {
      errEmail.push(errorResponses.email.fourError);
    }
  }
  if (error.includes('exceeded')) {
    if (error.includes(errorResponses.general.one)) {
      errCode.push(errorResponses.general.oneError);
    }
  }
  if (error.includes('account')) {
  }
  if (error.includes('password') || error.includes('Password')) {
    if (error.includes(errorResponses.password.one)) {
      errPassword.push(errorResponses.password.oneError);
    }
    if (error.includes(errorResponses.password.two)) {
      errPassword.push(errorResponses.password.twoError);
    }
    if (error.includes(errorResponses.password.three)) {
      errPassword.push(errorResponses.password.threeError);
    }
    if (error.includes(errorResponses.password.four)) {
      errPassword.push(errorResponses.password.fourError);
    }
  }

  if (error.includes('attempt limit exceeded')) {
    errCode.push(errorResponses.general.oneError);
  }

  if (isEmpty(errEmail) && isEmpty(errPassword) && isEmpty(errCode)) {
    errOther.push(error);
  }

  setErrors({
    email: errEmail.join(' '),
    newPassword: errPassword.join(' '),
    code: errCode.join(' '),
    other: errOther.join(' '),
  });
};

const errorResponses = {
  password: {
    one: "'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\\\S]+.*[\\\\S]+$",
    oneError: 'Invalid password',
    two: 'Password did not conform with policy: Password must have numeric characters',
    twoError: 'Password must have numeric characters',
    three:
      'Password did not conform with policy: Password must have symbol characters',
    threeError: 'Password must have symbol characters',
    four: 'Password cannot be empty',
    fourError: 'Password must not be empty',
  },
  email: {
    one: 'Missing required parameter USERNAME',
    oneError: 'Email must not be empty.',
    two: 'Incorrect username or password',
    twoError: 'Incorrect username or password',
    three: 'User is not confirmed',
    threeError: 'User is not confirmed, please wait for approval',
    four: 'User is disabled',
    fourError: 'User is disabled',
  },
  code: {
    one: `'Incorrect code supplied. Please try again or go back to the 'Reset Password' screen.`,
    oneError: 'Code must not be empty.',
    two: 'Incorrect code supplied',
    twoError: 'Incorrect username or password',
  },
  general: {
    one: 'Attempt limit exceeded, please try after some time.',
    oneError:
      'Attempted to reset password too many times. Please wait 5 minutes and try again.',
  },
};
