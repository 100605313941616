export const acc000000000002 = {
  creditOne: `{
   "transactionType":"Credit",
   "amount":"1050.25",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440000",
   "description":"Load initial card balance",
   "date":"2022-01-01",
   "isRepeat":false
}`,
  creditOneRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440000",
   "transactionDate":"2022-01-01",
   "transactionTime":"23:34:44",
   "accountBalance":"1048.25",
   "accountAvailable":"1048.25"
}`,
  debitOne: `{
   "transactionType":"Debit",
   "amount":"16",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440001",
   "description":"PHILS BAKEHOUSE & CAFE\\\\ 72 HIGH STREET \\\\ NORTHCOTE \\\\ 3070 AUS",
   "date":"2021-01-01",
   "isRepeat":false
}
`,
  debitOneRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440001",
   "transactionDate":"2021-01-01",
   "transactionTime":"23:47:11",
   "accountBalance":"1032.25",
   "accountAvailable":"1032.25"
}`,
  debitTwo: `{
   "transactionType":"Debit",
   "amount":"25",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440002",
   "description":"Amazon AU Sydney South Ns Aus Card Xx00029",
   "date":"2021-01-01",
   "isRepeat":false
}
`,
  debitTwoRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440002",
   "transactionDate":"2021-01-01",
   "transactionTime":"23:52:09",
   "accountBalance":"1007.25",
   "accountAvailable":"1007.25"
}`,
  reversalOne: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440003",
   "originalTransactionId":"123e4567-e89b-12d3-a456-426655440002",
   "isRepeat":false
}`,
  reversalOneRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440003",
   "transactionDate":"2021-01-01",
   "transactionTime":"23:57:19",
   "accountBalance":"1032.25",
   "accountAvailable":"1032.25"
}`,
  transferFrom: `{
   "amount":"500",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440005",
   "description":"Rent",
   "date":"2022-01-01",
   "isRepeat":false
}`,
  transferTo: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440005",
   "transactionDate":"2022-01-01",
   "transactionTime":"00:37:16",
   "accountBalance":"532.25",
   "accountAvailable":"532.25"
}`,
  transaction: `{
   
}`,
  transactionRes: `{
   "cardNumber":"5004 43** **** 1111",
   "cardToken":"15004430000000268",
   "transactions":[
      {
         "effectiveDate":"2021-01-01",
         "postingTime":"23471113",
         "cardToken":"15004430000000268",
         "accountNbr":"00000000002",
         "product":"Mastercard Prepaid",
         "transDescription":"PHILS BAKEHOUSE & CAFE 72 HIGH STREET NORTH",
         "transactionId":"123e4567-e89b-12d3-a456-426655440001",
         "transactionAmount":{
            "amount":"-16.00",
            "currencyCode":"036"
         }
      }
   ]
}`,
  transactionFullRes: `{
    "cardNumber":"5004 43** **** 1111",
    "cardToken":"15004430000000268",
    "transactions":[
      {
        "effectiveDate":"2021-11-28",
        "postingTime":"23524636",
        "cardToken":"15004430000000029",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"CARD FEE",
        "transactionId":"",
        "transactionAmount":{
          "amount":"-1.00",
          "currencyCode":"036"
        }
      },
      {
        "effectiveDate":"2021-11-30",
        "postingTime":"06201989",
        "cardToken":"15004430000000136",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"CARD FEE",
        "transactionId":"",
        "transactionAmount":{
          "amount":"-1.00",
          "currencyCode":"036"
        }
      },
      {
        "effectiveDate":"2022-01-01",
        "postingTime":"23344408",
        "cardToken":"15004430000000268",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"Load initial card balance",
        "transactionId":"123e4567-e89b-12d3-a456-426655440000",
        "transactionAmount":{
          "amount":"1050.25",
          "currencyCode":"036"
        }
      },
      {
        "effectiveDate":"2022-01-01",
        "postingTime":"23471113",
        "cardToken":"15004430000000268",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"PHILS BAKEHOUSE & CAFE 72 HIGH STREET NORT",
        "transactionId":"123e4567-e89b-12d3-a456-426655440001",
        "transactionAmount":{
          "amount":"-16.00",
          "currencyCode":"036"
        }
      },
      {
        "effectiveDate":"2022-01-01",
        "postingTime":"23520929",
        "cardToken":"15004430000000268",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"Amazon AU Sydney South Ns Aus Card Xx00029",
        "transactionId":"123e4567-e89b-12d3-a456-426655440002",
        "transactionAmount":{
          "amount":"-25.00",
          "currencyCode":"036"
        }
      },
      {
        "effectiveDate":"2022-01-01",
        "postingTime":"23571953",
        "cardToken":"15004430000000268",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"API DEBIT ACCOUNT TRANS. REV.",
        "transactionId":"123e4567-e89b-12d3-a456-426655440003",
        "transactionAmount":{
          "amount":"25.00",
          "currencyCode":"036"
        }
      },
      {
        "effectiveDate":"2022-01-01",
        "postingTime":"00371654",
        "cardToken":"15004430000000268",
        "accountNbr":"00000000002",
        "product":"Mastercard Prepaid",
        "transDescription":"Rent",
        "transactionId":"123e4567-e89b-12d3-a456-426655440005",
        "transactionAmount":{
          "amount":"-500.00",
          "currencyCode":"036"
        }
      }
    ]
  }`,
};
