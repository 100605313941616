import Logo from '../../assets/Logo.png';
import {
  HeaderContainer,
  HeaderLeft,
  HeaderMid,
  HeaderRight,
  LogoImage,
} from './Header.styles';
import '@aws-amplify/ui-react';
import { StyledButton, StyledHeaderText } from '../../app.styles';
import { ROUTES } from '../../AppRoutes';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';

export const Header = ({ pageName }) => {
  const { route, signOut } = useAuthenticator((context) => [
    context.user,
    context.route,
    context.signOut,
    context.authStatus,
  ]);
  const navigate = useNavigate();

  const logoutUser = () => {
    signOut();
    logInUser();
  };

  const logInUser = () => {
    navigate(ROUTES.AUTH.LOG_IN, { replace: true });
  };
  const isDev = false; // process.env.NODE_ENV === 'development';

  return (
    <>
      <HeaderContainer>
        <HeaderLeft>
          <a href={ROUTES.EXTERNAL.CHANGE_SITE_URL}>
            <LogoImage src={Logo} />
          </a>
        </HeaderLeft>

        <HeaderMid>
          {isDev && (
            <StyledHeaderText color={'white'} background={`var('--blue')`}>
              {route}
              {pageName}
            </StyledHeaderText>
          )}
        </HeaderMid>

        <HeaderRight>
          {pageName === ROUTES.LANDING && (
            <StyledButton
              onClick={() => logoutUser()}
              type="button"
              className="ninja-forms-field nf-element"
            >
              LOG OUT
            </StyledButton>
          )}
        </HeaderRight>
      </HeaderContainer>
    </>
  );
};
