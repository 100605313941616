import styled from 'styled-components/macro';
import { Visibility } from '@styled-icons/material-outlined';
import { VisibilityOff } from '@styled-icons/material/VisibilityOff';

export const ErrorLabel = styled.label`
  color: red;
  font-size: 12px;
`;

export const ErrorAsterisks = styled.span`
  color: red;
  font-size: 16px;
  font-weight: bold;
`;

const StyledInput = styled.input`
  width: 100%;
`;

export const HelperText = styled.label`
  font-size: 12px;
`;
export const StyledLabel = styled.label`
  font-weight: 900;
`;

export const Input = ({
  name,
  value,
  type,
  change,
  label,
  error,
  required,
  helperText,
  autoComplete,
}) => {
  return (
    <>
      <StyledLabel htmlFor={name} id="nf-label-field-9">
        {label} {required && <ErrorAsterisks>*</ErrorAsterisks>}
      </StyledLabel>
      <StyledInput
        className="ninja-forms-field nf-element"
        value={value}
        name={name}
        onChange={(e) => change(e.target.value)}
        type={type}
        autoComplete={autoComplete}
      />
      {helperText && <HelperText> {helperText}</HelperText>}
      <ErrorLabel>{error}</ErrorLabel>
    </>
  );
};

const StyledPasswordInput = styled.input`
  max-width: 90%;
  margin-right: 5px;
`;

export const StyledShowPasswordButton = styled.button`
  padding: 5px;
  font-weight: 300;
  border-style: solid;
  border-radius: 5px;
  background-color: ${({ background }) =>
    background ? background : 'transparent'};
  border-color: lightgrey;
  color: ${({ color }) => (color ? color : 'transparent')};
  border-width: thin;
`;
export const PasswordInputContainer = styled.div`
  display: flex;
`;

export const PasswordIconContainer = styled.div`
  flex-wrap: inherit;
`;

export const StyledIconVisibility = styled(Visibility)`
  color: grey;
`;

export const StyledIconVisibilityOff = styled(VisibilityOff)`
  color: lightgrey;
`;

export const PasswordInput = ({
  name,
  value,
  type,
  change,
  label,
  error,
  required,
  helperText,
  autoComplete,
  showPasswordToggle,
}) => {
  return (
    <>
      <StyledLabel htmlFor={name} id="nf-label-field-9">
        {label} {required && <ErrorAsterisks>*</ErrorAsterisks>}
      </StyledLabel>
      <PasswordInputContainer>
        <StyledPasswordInput
          className="ninja-forms-field nf-element"
          value={value}
          name={name}
          onChange={(e) => change(e.target.value)}
          type={type}
          autoComplete={autoComplete}
        />
        {showPasswordToggle && (
          <>
            <PasswordIconContainer> {showPasswordToggle}</PasswordIconContainer>
          </>
        )}
      </PasswordInputContainer>

      {helperText && <HelperText> {helperText}</HelperText>}

      <ErrorLabel>{error}</ErrorLabel>
    </>
  );
};
