import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from './GlobalStyle';
import { Amplify } from 'aws-amplify';
import { awsConfig } from './auth/aws-config';
import App from './App';

Amplify.configure(awsConfig);

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
