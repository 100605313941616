import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { ErrorLabel, Input } from '../../components/common/Input';
import 'react-phone-input-2/lib/style.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  AuthLayout,
  InputContainer,
  StyledButton,
  StyledHeader,
  StyledLink,
} from '../../app.styles';
import { StyledHelperMessage } from '../../styles/login-styled';
import { Loader } from '../../components/common/loaders';
import { handleErrors } from '../../helper/errorHelperLogin';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ROUTES } from '../../AppRoutes';
import isEmpty from 'lodash/isEmpty';

export const Login = () => {
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = () => {
    const to = ROUTES.AUTH.FORGOT;
    const options = {
      state: {
        email: email,
      },
      replace: true,
    };
    return navigate(to, options);
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    const isEmptyErr = {};

    if (isEmpty(email)) {
      isEmptyErr.email = 'Email must not be empty';
    }
    if (isEmpty(password)) {
      isEmptyErr.password = 'Password must not be empty';
    }
    if (!isEmpty(isEmptyErr)) {
      setLoading(false);
      setErrors(isEmptyErr);
      return;
    }
    Auth.signIn(email, password)
      .then((data) => {
        console.log('Logged in!');
        setLoading(false);
        navigate(ROUTES.LANDING, { replace: true });
      })
      .catch((error) => {
        handleErrors(JSON.stringify(error.message), errors, setErrors);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    if (errors) {
      console.info('errors:', errors);
    }
  }, [errors]);

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit}>
        <InputContainer>
          {loading && <Loader />}
          <StyledHeader>Login</StyledHeader>
          <Input
            value={email}
            name="email"
            change={setEmail}
            type="email"
            placeholder="Email"
            autoComplete="current_email"
            label="Email"
            error={errors?.email || ''}
            required
          />
          <Input
            value={password}
            name="password"
            change={setPassword}
            type="password"
            placeholder="Password"
            label="Password"
            autoComplete="current-password"
            error={errors?.password || ''}
            required
          />
          <>
            <div>
              <StyledHelperMessage>
                {' '}
                Don't have an account yet?
                <Link to="/signup">SIGN UP</Link>{' '}
              </StyledHelperMessage>
              <StyledHelperMessage>
                {' '}
                Forgot Password?
                <StyledLink
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleForgotPassword(e);
                  }}
                >
                  FORGOT PASSWORD
                </StyledLink>{' '}
              </StyledHelperMessage>
            </div>
          </>
          {errors?.other && <ErrorLabel>{errors?.other}</ErrorLabel>}
          <StyledButton
            type="submit"
            disabled={loading}
            className="ninja-forms-field nf-element "
          >
            LOG IN
          </StyledButton>
        </InputContainer>
      </form>
    </AuthLayout>
  );
};
