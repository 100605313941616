import styled from 'styled-components/macro';
import { Home } from '@styled-icons/boxicons-solid/Home';

export const MenuContainer = styled.div`
  height: 100vh;
  width: 270px;
  background-color: #f3f5f5;
  overflow: scroll;
  position: fixed;
  @media (max-width: 1200px) {
    display: none;
  }
  padding: 20px;
  padding-top: 20px;
`;
export const HomeIcon = styled(Home)`
  width: 18px;
  margin-top: -5px;
`;

export const MenuItemContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
  margin-left: 20px;
`;
export const MenuHeaderContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: row;
`;

export const StyledTextAnchor = styled.a`
  font-size: 16px;
  text-align: left;
  margin: 0 !important;
  font-weight: 500;
  color: black;
  cursor: pointer;
  &:hover {
    color: #f36e57;
  }
`;

export const StyledHeader = styled.h4`
  font-size: 16px;
  text-align: left;
  font-weight: bold;
  margin: 0;
  color: black;
`;
