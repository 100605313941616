import { Authenticator, View } from '@aws-amplify/ui-react';
import './change-financial.css';
import { AppRoutes } from './AppRoutes';

function App() {
  return (
    <Authenticator.Provider>
      <AppRoutes />
    </Authenticator.Provider>
  );
}

export default App;
