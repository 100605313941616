import styled from 'styled-components/macro';
import { Flex, Menu, MenuButton, View, Button } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

// TODO: Should be using a theme - adapting from the supplied change css.
export const changeTheme = {
  name: 'change-theme',
  tokens: {
    colors: {
      font: [
        { primary: 'var(--orange)' },
        { secondary: 'var(--white)' },
        { tertiary: 'var(--blue)' },
      ],
    },
    buttons: {
      colors: [
        { primary: 'var(--orange)' },
        { secondary: 'var(--white)' },
        { tertiary: 'var(--blue)' },
      ],
    },
    backgroundColor: [
      { primary: 'var(--orange)' },
      { secondary: 'var(--white)' },
      { tertiary: 'var(--blue)' },
    ],
  },
};

export const StyledView = styled(View)`
  background-color: white;
  color: var(--bs-orange);
  font-size: 16px;
  letter-spacing: 0.2em;
  font-weight: 900;
`;

export const StyledMenu = styled(Menu)`
  color: var(--orange);
  background-color: white;
`;

export const StyledFlex = styled(Flex)`
  color: var(--dark-blue);
  background-color: white;
`;

export const StyledAwsButton = styled(Button)`
  color: var(--blue);
  background-color: var(bs-white);
  font-size: 16px;
  letter-spacing: 0.2em;
  font-weight: 900;
  padding: 10px 10px;
  margin: 10px 0;
`;

export const StyledAwsMenuButton = styled(MenuButton)`
  color: var(--white);
  border-color: var(--orange);
  :hover {
    color: var(--orange);
    border-color: var(--blue);
  }
  --amplify-colors-font-hover: var(--white);
  --amplify-colors-background-secondary: var(--white);
`;

export const awsTheme = styled.body`
  :root [data-amplify-authenticator] {
    --amplify-colors-background-primary: var(--amplify-colors-neutral-90);
    --amplify-colors-background-secondary: var(--amplify-colors-neutral-100);
    --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
    --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
    --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
    --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
    --amplify-colors-font-interactive: var(--amplify-colors-white);
    --amplify-components-tabs-item-active-color: var(--amplify-colors-white);
    --amplify-components-tabs-item-focus-color: var(--amplify-colors-white);
    --amplify-components-tabs-item-hover-color: var(--amplify-colors-orange);
  }
`;
