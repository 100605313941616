import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { StyledAwsButton } from '../../../styles/aws-styled';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AuthLayout, InputContainer, StyledHeader } from '../../../app.styles';
import '@aws-amplify/ui-react/styles.css';
import { Input } from '../../../components/common/Input';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

// Send confirmation code to user's email
export const ForgotPassword = () => {
  const { user, codeDeliveryDetails, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const [resetDetails, setResetDetails] = useState();
  const [username, setUsername] = useState();
  const [emailErrors, setEmailErrors] = useState();
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (location.search) {
      setUsername(searchParams.get('email'));
    }
  }, [location, setUsername, searchParams]);

  useEffect(() => {
    if (route === 'confirmResetPassword' && codeDeliveryDetails) {
      setResetDetails(codeDeliveryDetails);
    }
  }, [route, codeDeliveryDetails, setResetDetails, location]);

  // INFO See docs: https://docs.amplify.aws/lib/auth/manageusers/q/platform/js/#change-password
  // I've just wrapped them so that they're synchronous.
  const handleForgotPassword = (event) => {
    event.preventDefault();
    const isEmptyErr = {};

    if (isEmpty(username)) {
      isEmptyErr.email = 'Email must not be empty';
    }

    if (!isEmpty(isEmptyErr)) {
      setErrors(isEmptyErr);
      return;
    }

    Auth.forgotPassword(username)
      .then((resetPasswordState) => {
        navigate('/confirm', {
          searchParams: location.search,
          state: {
            resetPasswordState: resetPasswordState,
            buttonText: 'Go To Reset Password',
          },
        });
      })
      .catch((error) => {
        setEmailErrors(error);
      });
  };

  return (
    <>
      {resetDetails && (
        <>
          <pre>
            <code>{resetDetails}</code>
          </pre>
        </>
      )}

      {!resetDetails && (
        <>
          <AuthLayout>
            <form onSubmit={handleForgotPassword}>
              <StyledHeader>Reset Your Password</StyledHeader>
              <InputContainer>
                <Input
                  value={username}
                  name="email"
                  autoComplete="current_email"
                  change={setUsername}
                  type="email"
                  placeholder="Email"
                  label="Email"
                  error={(emailErrors && emailErrors) || errors?.email}
                  required
                />
              </InputContainer>
              <StyledAwsButton
                type="submit"
                disabled={emailErrors}
                className="ninja-forms-field nf-element"
              >
                RESET PASSWORD
              </StyledAwsButton>
            </form>
          </AuthLayout>
        </>
      )}
    </>
  );
};
