import {
  HelperTextBasicContainer,
  AuthLayout,
  StyledHeader,
  StyledSpanBold,
  StyledText,
} from '../../../app.styles';
import { StyledAwsMenuButton } from '../../../styles/aws-styled';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ROUTES } from '../../../AppRoutes';

export const ConfirmPasswordReset = () => {
  const defaultButtonText = 'Back to Home';
  const [buttonText, setButtonText] = useState(defaultButtonText);
  const [resetPasswordState, setResetPasswordState] = useState();
  const [userHasCode, setUserHasCode] = useState(false);
  const { user, route } = useAuthenticator((context) => [
    context.user,
    context.route,
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  const goToResetPage = (e) => {
    e.preventDefault();
    navigate(ROUTES.AUTH.RESET, { replace: true });
  };

  useEffect(() => {
    if (location.state) {
      setButtonText(location.state.buttonText);
      setResetPasswordState(
        location.state.resetPasswordState.CodeDeliveryDetails
      );
    }
  }, [location, setButtonText, setResetPasswordState]);

  useEffect(() => {
    if (location.state) {
      setButtonText(location.state.buttonText);
    }
  }, [location, setButtonText]);

  return (
    <>
      <AuthLayout>
        <HelperTextBasicContainer>
          {resetPasswordState ? (
            <>
              <StyledHeader> Please confirm your details</StyledHeader>
              <StyledText>
                {'A message has been sent to your '}
                <StyledSpanBold>
                  {resetPasswordState.DeliveryMedium}
                </StyledSpanBold>
                {' account at: '}
                <StyledSpanBold>
                  {resetPasswordState.Destination}
                </StyledSpanBold>
              </StyledText>
              <StyledText>
                {'To complete the reset open your '}
                <StyledSpanBold>
                  {resetPasswordState.DeliveryMedium}
                </StyledSpanBold>
                {' and copy the '}
                <StyledSpanBold>code</StyledSpanBold>
                {' contained within the message.'}
              </StyledText>
            </>
          ) : (
            <>
              <StyledHeader> Please Confirm </StyledHeader>
              <StyledText>
                Please check your email and click the link to confirm your
                password reset.
              </StyledText>
            </>
          )}
          {!userHasCode && (
            <StyledAwsMenuButton
              onClick={(e) => {
                e.preventDefault();
                setUserHasCode(!userHasCode);
              }}
              type="button"
              className="ninja-forms-field nf-element"
            >
              {'I have copied my reset code'}
            </StyledAwsMenuButton>
          )}

          {userHasCode && buttonText && (
            <StyledAwsMenuButton
              onClick={(e) => goToResetPage(e)}
              type="button"
              className="ninja-forms-field nf-element"
            >
              {buttonText}
            </StyledAwsMenuButton>
          )}
        </HelperTextBasicContainer>
      </AuthLayout>
    </>
  );
};
