import { useEffect, useState } from 'react';
import {
  ErrorLabel,
  Input,
  PasswordInput,
  StyledIconVisibility,
  StyledIconVisibilityOff,
  StyledShowPasswordButton,
} from '../../components/common/Input';
import 'react-phone-input-2/lib/style.css';
import { LocationSelector } from '../../components/common/Location';
import { PhoneNumber } from '../../components/common/PhoneNumber';
import { Link, useNavigate } from 'react-router-dom';
import {
  AuthLayout,
  InputContainer,
  MessageTextFlexContainer,
  StyledButton,
  StyledHeader,
  StyledText,
} from '../../app.styles';
import { handleErrors } from '../../helper/errorHelperSignup';
import { Loader } from '../../components/common/loaders';
import { Auth } from 'aws-amplify';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { ROUTES } from '../../AppRoutes';
import isEmpty from 'lodash/isEmpty';

export const Signup = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  if (user) navigate('/');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('Australia');
  const [state, setState] = useState('');
  const [company, setCompany] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setName(() => `${firstName} ${lastName}`);
  }, [firstName, lastName]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors({});
    const isEmptyErr = {};
    try {
      if (isEmpty(email)) {
        isEmptyErr.email = 'Email must not be empty';
      }
      if (isEmpty(password)) {
        isEmptyErr.password = 'Password must not be empty';
      }
      if (isEmpty(confirmPassword)) {
        isEmptyErr.confirmPassword = 'Confirm Password must not be empty';
      }
      if (isEmpty(firstName)) {
        isEmptyErr.firstName = 'First name must not be empty';
      }
      if (isEmpty(lastName)) {
        isEmptyErr.lastName = 'Last Name must not be empty';
      }
      if (isEmpty(phoneNumber)) {
        isEmptyErr.phoneNumber = 'Phone number must not be empty';
      }
      if (isEmpty(company)) {
        isEmptyErr.company = 'Company must not be empty';
      }
      if (isEmpty(country)) {
        isEmptyErr.country = 'Country must not be empty';
      }
      if (isEmpty(state)) {
        isEmptyErr.state = 'State must not be empty';
      }
      if (password !== confirmPassword) {
        isEmptyErr.confirmPassword = 'Passwords do not match.';
      }
      if (!isEmpty(isEmptyErr)) {
        setLoading(false);
        setErrors(isEmptyErr);
        return;
      }
      const signUpParams = {
        username: email,
        password: password,
        attributes: {
          name: name,
          given_name: firstName,
          family_name: lastName,
          phone_number: '+' + phoneNumber,
          profile: company,
          zoneinfo: country,
          locale: state,
        },
      };

      const { user } = await Auth.signUp(signUpParams);
      if (user) {
        setSuccess(
          'Successfully signed up, please check your email and verify your account.'
        );
        setPassword('');
        setFirstName('');
        setLastName('');
        setPhoneNumber('');
        setCountry('Australia');
        setState('');
        setCompany('');
      }
    } catch (err) {
      handleErrors(JSON.stringify(err.message), errors, setErrors);
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      {success && (
        <>
          <MessageTextFlexContainer>
            <StyledHeader>Signup Success</StyledHeader>
            <StyledText>
              {success}
              <StyledText>{'Click this button to proceed:'}</StyledText>
              <StyledButton
                type="button"
                disabled={loading}
                className="ninja-forms-field nf-element"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(ROUTES.AUTH.LOG_IN, { replace: true });
                }}
              >
                LOG IN
              </StyledButton>
            </StyledText>
          </MessageTextFlexContainer>
        </>
      )}
      {!success && (
        <form onSubmit={onSubmit}>
          <InputContainer>
            {loading && <Loader />}
            <StyledHeader>Signup</StyledHeader>

            <Input
              value={email}
              name="email"
              change={setEmail}
              type="email"
              autoComplete="username"
              placeholder="Email"
              label="Email"
              error={errors?.email || ''}
              required
            />
            <PasswordInput
              value={password}
              name="password"
              change={setPassword}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              autoComplete="new-password"
              label="Password"
              error={errors?.password || ''}
              helperText={
                errors?.password
                  ? errors.password
                  : 'Password must be a combination of numbers, special characters, uppercase and lowercase letters.'
              }
              required
              showPasswordToggle={
                <StyledShowPasswordButton
                  type="button"
                  aria-label="Show password as plain text. Warning: this will display your password on the screen."
                  onClick={() => setShowPassword(!showPassword)}
                  borderColour={showPassword ? 'grey' : 'lightgrey'}
                >
                  {showPassword ? (
                    <StyledIconVisibilityOff aria-label="Hide" size="24" />
                  ) : (
                    <StyledIconVisibility aria-label="Show" size="24" />
                  )}
                </StyledShowPasswordButton>
              }
            />
            <Input
              value={confirmPassword}
              name="confirm_password"
              change={setConfirmPassword}
              type={showPassword ? 'text' : 'password'}
              placeholder="Confirm Password"
              autoComplete="confirm-password"
              quiet
              label="Confirm Password"
              error={errors?.confirmPassword || ''}
              helperText="Confirm Password must match Password input above."
              required
            />
            <Input
              value={firstName}
              name="firstName"
              change={setFirstName}
              type="text"
              placeholder="First Name"
              label="First Name"
              error={errors?.firstName || ''}
              required
            />
            <Input
              value={lastName}
              name="lastName"
              change={setLastName}
              type="text"
              placeholder="Last Name - e.g. Smith"
              label="Last Name"
              error={errors?.lastName || ''}
              required
            />
            <PhoneNumber
              errors={errors}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              required
            />
            <Input
              value={company}
              name="company"
              change={setCompany}
              type="text"
              placeholder="Company"
              label="Company Name"
              error={errors?.company || ''}
              required
            />
            <LocationSelector
              errors={errors}
              country={country}
              setCountry={setCountry}
              state={state}
              setState={setState}
            />
            <p>
              Already have an account? <Link to="/login">LOG IN</Link>
            </p>
            {errors?.other && <ErrorLabel>{errors?.other}</ErrorLabel>}
            <StyledButton
              type="submit"
              disabled={loading}
              className="ninja-forms-field nf-element"
            >
              SIGN UP
            </StyledButton>
          </InputContainer>
        </form>
      )}
    </AuthLayout>
  );
};
