export const menuItems = [
  {
    header: 'Customer',
    items: [
      {
        href: '#post/v1/customer',
        text: '1. Create Customer',
      },
      {
        href: '#get/v1/customer',
        text: '  2. Once customer is created',
      },
    ],
  },
  {
    header: 'Products',
    items: [
      {
        href: '#/v1/product/list',
        text: '3. List Products',
      },
      {
        href: '#/v1/product/{cardProduct}/application/debitOrprepaid/',
        text: '4. Apply for a card Product',
      },
    ],
  },
  {
    header: 'Card',
    items: [
      {
        href: '#/v1/card/{cardNumber}/activate',
        text: '5. Activate Card',
      },
      {
        href: '#/v1/system/rsa',
        text: '6. Set a PIN (optional)',
      },
      {
        href: '#/v1/card/{cardNumber}/services',
        text: '7. List the services allowed on the card',
      },
      {
        href: '#/v1/card/{cardNumber}/block',
        text: '8. Temporary block / unblock a card',
      },
      {
        href: '#/v1/card/{cardNumber}/services',
        text: '9. Overwrite service setup at card level',
      },
      {
        href: '#/v1/card/{cardNumber}/reissue',
        text: '10. Reissue the same card',
      },
      {
        href: '#/v1/card/{cardTokenNbr}/cardRSAEncrypted',
        text: ' 11. Request clear card and CVV2 encrypted under a provided public key',
      },
      {
        href: '#/v1/card/{cardNumber}/account/list',
        text: '12. List of accounts a card can transact on',
      },
      {
        href: '#/v1/card/{cardNumber}/cancel',
        text: '13. Cancel a card (i.e. stolen / lost)',
      },
      {
        href: '#/v1/card/{cardNumber}/replace',
        text: '14. Replace the card',
      },
    ],
  },
  {
    header: 'Account & Account Transactions',
    items: [
      {
        href: '#/v1/account/{account}/application/additionalCard/{customerNumber}',
        text: '15. Apply for an additional card on the account',
      },
      {
        href: '#/v1/account/{account}/card/list',
        text: '16. Retrieves a list of the cards associated with the account.',
      },
      {
        href: '#/v1/account/{account}/details',
        text: '17. Get the account details',
      },
      {
        href: '#/v1/account/{account}/transaction/credit',
        text: '18. Post a credit amount',
      },
      {
        href: '#/v1/account/{account}/transaction/debit',
        text: '19. Post a debit amount',
      },
      {
        href: '#/v1/account/{account}/transaction/transfer/{toAccount}',
        text: '20. Post a transfer from one account to another account',
      },
      {
        href: '#/v1/account/{account}/transaction/reversal',
        text: '21. Post a reversal of a transaction (credit, debit or transfer)',
      },

      {
        href: '#/v1/account/{account}/balance',
        text: '22. Get the balance of an account',
      },
      {
        href: '#/v1/account/{account}/transaction/list',
        text: '23. Get the list of transactions posted to an account',
      },

      {
        href: '#/v1/account/{account}/transaction/{transactionId}',
        text: '24. Get the detail of one transaction',
      },
      {
        href: '#transactionFlowExample',
        text: '25. Transaction Flow Example',
      },
    ],
  },
  {
    header: 'Management',
    items: [
      {
        href: '#/v1/management/customer/{customerNumber}',
        text: '1. Get customer internal attributes',
      },
      {
        href: '#/v1/management/customer/{customerNumber}',
        text: '2. Update customer internal attributes',
      },
    ],
  },
  {
    header: 'System',
    items: [
      {
        href: '#/v1/system/codes/addressType',
        text: 'Codes Descriptions',
      },
      {
        href: '#appendix',
        text: 'Appendix',
      },
    ],
  },
];
