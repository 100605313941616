import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { StyledAwsButton } from '../../../styles/aws-styled';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  AuthLayout,
  InfoIcon,
  InputContainer,
  StyledHeader,
  StyledLink,
  StyledText,
} from '../../../app.styles';
import '@aws-amplify/ui-react/styles.css';
import {
  ErrorLabel,
  Input,
  PasswordInput,
  StyledIconVisibility,
  StyledIconVisibilityOff,
  StyledShowPasswordButton,
} from '../../../components/common/Input';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleResetPasswordErrors } from '../../../helper/errorHelperResetPassword';

// Send confirmation code to user's email
export const ResetPassword = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const { user, codeDeliveryDetails, resendCode, route } = useAuthenticator(
    (context) => [context.user, context.route, context.codeDeliveryDetails]
  );

  const [resetDetails, setResetDetails] = useState(null);
  const [username, setUsername] = useState(null);
  const [code, setCode] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [errors, setErrors] = useState({
    email: null,
    newPassword: null,
    code: null,
  });
  const [toggleHelp, setToggleHelp] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (route === 'confirmResetPassword') {
      setResetDetails(codeDeliveryDetails);
    }
  }, [route, codeDeliveryDetails, setResetDetails, user]);

  useEffect(() => {
    console.log(location);
  }, [location]);

  useEffect(() => {
    if (resetSuccess) {
      let timedMessage = setTimeout(() => {
        navigate('/login', {
          state: {
            username: username,
            password: newPassword,
          },
        });
      }, 5000);
      return () => clearTimeout(timedMessage);
    }
  }, [navigate, newPassword, resetSuccess, username]);

  // This is to render a new password once the code has been received from above.
  const submitNewPassword = (e) => {
    e.preventDefault();
    return Auth.forgotPasswordSubmit(username, code, newPassword)
      .then((data) => {
        setResetDetails(data);
        setResetSuccess(true);
        return data;
      })
      .catch((error) => {
        handleResetPasswordErrors(
          JSON.stringify(error.message),
          errors,
          setErrors
        );
      });
  };

  return (
    <>
      {resetSuccess && (
        <>
          <AuthLayout>
            <StyledText>
              {'Password successfully reset. '}{' '}
              {'You will be redirected to the Log In screen in a few seconds'}
            </StyledText>
          </AuthLayout>
        </>
      )}

      {!resetDetails && (
        <>
          <AuthLayout>
            <form
              onSubmit={async (e) => {
                await submitNewPassword(e);
              }}
            >
              <StyledHeader>Reset Your Password</StyledHeader>
              <InputContainer>
                <Input
                  value={username}
                  name="email"
                  autoComplete="current_email"
                  change={setUsername}
                  type="email"
                  placeholder="Email"
                  label="Email"
                  error={errors && errors.email}
                  required
                />
              </InputContainer>
              <InputContainer>
                <Input
                  value={code}
                  name="code"
                  autoComplete="off"
                  change={setCode}
                  type="text"
                  placeholder="Reset Code"
                  label="Code"
                  helperText={'Check your email for a reset code'}
                  error={errors && errors.code}
                  required
                />
              </InputContainer>
              <InputContainer>
                <PasswordInput
                  value={newPassword}
                  name="newPassword"
                  autoComplete="new_password"
                  change={setNewPassword}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="*****"
                  label="New Password"
                  helperText={
                    errors?.password
                      ? errors.password
                      : 'Password must be a combination of numbers, special characters, uppercase and lowercase letters.'
                  }
                  error={errors.newPassword && errors.newPassword}
                  required
                  showPasswordToggle={
                    <StyledShowPasswordButton
                      type="button"
                      aria-label="Show password as plain text. Warning: this will display your password on the screen."
                      onClick={() => setShowPassword(!showPassword)}
                      borderColour={showPassword ? 'grey' : 'lightgrey'}
                    >
                      {showPassword ? (
                        <StyledIconVisibilityOff aria-label="Hide" size="24" />
                      ) : (
                        <StyledIconVisibility aria-label="Show" size="24" />
                      )}
                    </StyledShowPasswordButton>
                  }
                />
              </InputContainer>
              {errors?.other && (
                <div>
                  <ErrorLabel>{errors?.other}</ErrorLabel>
                </div>
              )}
              <StyledAwsButton
                type="submit"
                disabled={
                  errors && (errors.password || errors.email || errors.code)
                }
                className="ninja-forms-field nf-element"
              >
                RESET PASSWORD
              </StyledAwsButton>
              {
                <>
                  <StyledText>
                    {"Haven't received a code yet? "}
                    <StyledAwsButton
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        setToggleHelp(!toggleHelp);
                      }}
                    >
                      <InfoIcon />
                    </StyledAwsButton>
                  </StyledText>

                  {toggleHelp && (
                    <>
                      <StyledText>
                        {'It may take up to a few minutes to arrive.'}
                      </StyledText>
                      <StyledText>
                        {'Try refreshing your email or click here to  '}
                        <StyledLink
                          onClick={async (e) => {
                            e.preventDefault();
                            await resendCode();
                          }}
                        >
                          RESEND CODE
                        </StyledLink>
                      </StyledText>
                    </>
                  )}
                </>
              }
            </form>
          </AuthLayout>
        </>
      )}
    </>
  );
};
