import { createGlobalStyle } from 'styled-components';
import CircularStdBlack from './assets/CircularStd-Black.otf';
import CircularStdBold from './assets/CircularStd-Bold.otf';
import CircularStdBoldItalic from './assets/CircularStd-BoldItalic.otf';
import CircularStdBook from './assets/CircularStd-Book.otf';
import CircularStdBookItalic from './assets/CircularStd-BookItalic.otf';
import CircularStdLight from './assets/CircularStd-Light.otf';
import CircularStdLightItalic from './assets/CircularStd-Light Italic.otf';
import CircularStdMedium from './assets/CircularStd-Medium.otf';
import CircularStdMediumItalic from './assets/CircularStd-MediumItalic.otf';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'CircularStdBlack';
    src: url(${CircularStdBlack});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdBold';
    src: url(${CircularStdBold});
  }
  @font-face {
    font-family: 'CircularStdBoldItalic';
    src: url(${CircularStdBoldItalic});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdBook';
    src: url(${CircularStdBook});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdBookItalic';
    src: url(${CircularStdBookItalic});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdLight';
    src: url(${CircularStdLight});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdLightItalic';
    src: url(${CircularStdLightItalic});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdMedium';
    src: url(${CircularStdMedium});
    font-display: fallback;
  }
  @font-face {
    font-family: 'CircularStdMediumItalic';
    src: url(${CircularStdMediumItalic});
    font-display: fallback;
  }

  a {
     font-family: 'CircularStdLight', sans-serif;
  }
  p {
    font-family: 'CircularStdLight', sans-serif;
  }
  body > footer {
    position: sticky;
    top: 100vh;
  }
`;

export default GlobalStyle;
