import { Timeline, Event } from 'react-timeline-scribble';
import { nord } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { acc000000000002 } from '../../constants/acc000000000002';
import {
  EndpointText,
  HelperText,
  RESTType,
  StyledText,
} from '../../app.styles';
import {
  ItalicText,
  StyledHighlighter,
  WalkthroughContainer,
} from './Walkthrough.styles';

export const WalkthroughTwo = () => {
  const {
    creditOne,
    creditOneRes,
    debitOne,
    debitOneRes,
    debitTwo,
    debitTwoRes,
    reversalOne,
    reversalOneRes,
    transferFrom,
    transferTo,
    transaction,
    transactionRes,
    transactionFullRes,
  } = acc000000000002;

  const baseUrl = process.env.REACT_APP_API_DOC_ENDPOINT_BASE_ROUTE;

  return (
    <>
      <HelperText>Transaction flow - John Doe </HelperText>
      <StyledText>
        customerNumber: 0000000078 account: 00000000002, cardToken:
        15004430000000029
      </StyledText>
      <WalkthroughContainer>
        <Timeline>
          <Event interval={'Credit the account'}>
            <EndpointText>
              <RESTType type="post">POST</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/creditAmountInAccount`}
              >{`/v1/account/{account}/transaction/credit`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 to credit the
            account using this body
            <StyledHighlighter language="json" style={nord}>
              {creditOne}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {creditOneRes}
            </StyledHighlighter>
            <ItalicText>
              * "transactionType" can also be adjustment or fees
            </ItalicText>
          </Event>
          <Event interval={'Debit the account'}>
            <EndpointText>
              <RESTType type="post">POST</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/debitAmountInAccount`}
              >{`/v1/account/{account}/transaction/debit`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 to debit the
            account using this body
            <StyledHighlighter language="json" style={nord}>
              {debitOne}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {debitOneRes}
            </StyledHighlighter>
            <ItalicText>
              * "transactionType" can also be adjustment or fees
            </ItalicText>
          </Event>
          <Event interval={'Debit the account again (for a reversal)'}>
            <EndpointText>
              <RESTType type="post">POST</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/debitAmountInAccount`}
              >{`/v1/account/{account}/transaction/debit`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 to credit the
            using this body
            <StyledHighlighter language="json" style={nord}>
              {debitTwo}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {debitTwoRes}
            </StyledHighlighter>
            <ItalicText>
              * "transactionType" can also be adjustment or fees
            </ItalicText>
          </Event>
          <Event interval={'Reverse the previous debit '}>
            <EndpointText>
              <RESTType type="post">POST</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/reverseCreditDebitOrTransfer`}
              >{`/v1/account/{account}/transaction/reversal`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 to credit the
            using this body
            <StyledHighlighter language="json" style={nord}>
              {reversalOne}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {reversalOneRes}
            </StyledHighlighter>
          </Event>
          <Event interval={'Transfer between accounts'}>
            <EndpointText>
              <RESTType type="post">POST</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/transferAmountBetweenAccounts`}
              >{`/v1/account/{account}/transaction/transfer/{toAccount}`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 to send the
            credit and toAccount : 00000000006 for the account to receive the
            credit
            <StyledHighlighter language="json" style={nord}>
              {transferFrom}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {transferTo}
            </StyledHighlighter>
          </Event>
          <Event interval={'Get Transaction by id'}>
            <EndpointText>
              <RESTType type="get">GET</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/getFinancialTransactionsbyId`}
              >{`/v1/account/{account}/transaction/{transactionId}`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 and for the
            transaction use transactionId: 123e4567-e89b-12d3-a456-426655440001
            <StyledHighlighter language="json" style={nord}>
              {transaction}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {transactionRes}
            </StyledHighlighter>
          </Event>
          <Event interval={'Get all account transactions'}>
            <EndpointText>
              <RESTType type="get">GET</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/getFinancialTransactionsOnAccount`}
              >{`/v1/account/{account}/transaction/list?dateFrom=2021-01-01&dateTo=2022-06-01`}</a>
            </EndpointText>
            <strong>Request:</strong> Use account: 00000000002 and for the query
            range use dateFrom: 2021-01-01 and dateTo: 2022-06-01
            <StyledHighlighter language="json" style={nord}>
              {transaction}
            </StyledHighlighter>
            <strong>Response:</strong>
            <StyledHighlighter language="json" style={nord}>
              {transactionFullRes}
            </StyledHighlighter>
          </Event>
        </Timeline>
      </WalkthroughContainer>
    </>
  );
};