import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { ErrorAsterisks, ErrorLabel, StyledLabel } from './Input';
import styled from 'styled-components/macro';

const StyledCountryDropdown = styled(CountryDropdown)``;
const StyledRegionDropdown = styled(RegionDropdown)``;
export const LocationSelector = ({
  errors,
  country,
  setCountry,
  state,
  setState,
}) => {
  return (
    <>
      <StyledLabel htmlFor="country">
        Country <ErrorAsterisks>*</ErrorAsterisks>
      </StyledLabel>
      <StyledCountryDropdown
        className="ninja-forms-field nf-element"
        value={country}
        onChange={(val) => setCountry(val)}
      />
      <ErrorLabel>{errors?.country || ''}</ErrorLabel>
      <StyledLabel htmlFor="state">
        State <ErrorAsterisks>*</ErrorAsterisks>
      </StyledLabel>
      <StyledRegionDropdown
        className="ninja-forms-field nf-element"
        country={country}
        value={state}
        onChange={(val) => setState(val)}
      />
      <ErrorLabel>{errors?.state || ''}</ErrorLabel>
    </>
  );
};
