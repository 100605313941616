export const acc000000000004 = {
  creditOne: `{
   "transactionType":"Credit",
   "amount":"2000",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440000",
   "description":"Load initial card balance",
   "date":"2022-01-01",
   "isRepeat":false
}`,
  creditOneRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440000",
   "transactionDate":"2022-01-01",
   "transactionTime":"02:28:21",
   "accountBalance":"1998.00",
   "accountAvailable":"1998.00"
}`,
  debitOne: `{
   "transactionType":"Debit",
   "amount":"130",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440010",
   "description":"Amazon AU Sydney South Ns Aus Card Xx00045",
   "date":"2022-02-02",
   "isRepeat":false
}
`,
  debitOneRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440010",
   "transactionDate":"2022-02-02",
   "transactionTime":"02:32:13",
   "accountBalance":"1868.00",
   "accountAvailable":"1868.00"
}`,
  debitTwo: `{
   "transactionType":"Debit",
   "amount":"210",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440011",
   "description":"Amazon AU Sydney South Ns Aus Card Xx00045",
   "date":"2022-02-02",
   "isRepeat":false
}
`,
  debitTwoRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440011",
   "transactionDate":"2022-02-02",
   "transactionTime":"02:33:41",
   "accountBalance":"1658.00",
   "accountAvailable":"1658.00"
}`,
  reversalOne: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440012",
   "originalTransactionId":"123e4567-e89b-12d3-a456-426655440011",
   "isRepeat":false
}`,
  reversalOneRes: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440012",
   "transactionDate":"2022-02-02",
   "transactionTime":"02:35:14",
   "accountBalance":"1868.00",
   "accountAvailable":"1868.00"
}`,
  transferFrom: `{
   "amount":"200",
   "currencyCode":"036",
   "transactionId":"123e4567-e89b-12d3-a456-426655440013",
   "description":"Rent",
   "date":"2022-01-01",
   "isRepeat":false
}`,
  transferTo: `{
   "transactionId":"123e4567-e89b-12d3-a456-426655440013",
   "transactionDate":"2022-01-01",
   "transactionTime":"02:43:17",
   "accountBalance":"1668.00",
   "accountAvailable":"1668.00"
}`,
  transaction: `{
   
}`,
  transactionRes: `{
    "cardNumber": "5004 43** **** 3472",
    "cardToken": "15004430000000045",
    "transactions": [
        {
            "effectiveDate": "2022-01-01",
            "postingTime": "02431772",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "Rent",
            "transactionId": "123e4567-e89b-12d3-a456-426655440013",
            "transactionAmount": {
                "amount": "-200.00",
                "currencyCode": "036"
            }
        }
    ]
    }`,
  transactionFullRes: `{
   "cardNumber":"5004 43** **** 3472",
   "cardToken":"15004430000000045",
   "transactions":[
      {
         "effectiveDate":"2021-11-28",
         "postingTime":"23590175",
         "cardToken":"15004430000000045",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"CARD FEE",
         "transactionId":"",
         "transactionAmount":{
            "amount":"-1.00",
            "currencyCode":"036"
         }
      },
      {
         "effectiveDate":"2021-11-30",
         "postingTime":"06324087",
         "cardToken":"15004430000000144",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"CARD FEE",
         "transactionId":"",
         "transactionAmount":{
            "amount":"-1.00",
            "currencyCode":"036"
         }
      },
      {
         "effectiveDate":"2022-01-01",
         "postingTime":"02282150",
         "cardToken":"15004430000000045",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"Load initial card balance",
         "transactionId":"123e4567-e89b-12d3-a456-426655440000",
         "transactionAmount":{
            "amount":"2000.00",
            "currencyCode":"036"
         }
      },
      {
         "effectiveDate":"2022-01-01",
         "postingTime":"02431772",
         "cardToken":"15004430000000045",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"Rent",
         "transactionId":"123e4567-e89b-12d3-a456-426655440013",
         "transactionAmount":{
            "amount":"-200.00",
            "currencyCode":"036"
         }
      },
      {
         "effectiveDate":"2022-01-19",
         "postingTime":"02321387",
         "cardToken":"15004430000000045",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"Amazon AU Sydney South Ns Aus Card Xx00045",
         "transactionId":"123e4567-e89b-12d3-a456-426655440010",
         "transactionAmount":{
            "amount":"-130.00",
            "currencyCode":"036"
         }
      },
      {
         "effectiveDate":"2022-01-19",
         "postingTime":"02334143",
         "cardToken":"15004430000000045",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"Amazon AU Sydney South Ns Aus Card Xx00045",
         "transactionId":"123e4567-e89b-12d3-a456-426655440011",
         "transactionAmount":{
            "amount":"-210.00",
            "currencyCode":"036"
         }
      },
      {
         "effectiveDate":"2022-01-19",
         "postingTime":"02351477",
         "cardToken":"15004430000000045",
         "accountNbr":"00000000004",
         "product":"Mastercard Prepaid",
         "transDescription":"API DEBIT ACCOUNT TRANS. REV.",
         "transactionId":"123e4567-e89b-12d3-a456-426655440012",
         "transactionAmount":{
            "amount":"210.00",
            "currencyCode":"036"
         }
      }
   ]
}`,
  fullTransactions: `{
    "cardNumber": "5004 43** **** 3472",
    "cardToken": "15004430000000045",
    "transactions": [
        {
            "effectiveDate": "2021-11-28",
            "postingTime": "23590175",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "CARD FEE",
            "transactionId": "",
            "transactionAmount": {
                "amount": "-1.00",
                "currencyCode": "036"
            }
        },
        {
            "effectiveDate": "2021-11-30",
            "postingTime": "06324087",
            "cardToken": "15004430000000144",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "CARD FEE",
            "transactionId": "",
            "transactionAmount": {
                "amount": "-1.00",
                "currencyCode": "036"
            }
        },
        {
            "effectiveDate": "2022-01-01",
            "postingTime": "02282150",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "Load initial card balance",
            "transactionId": "123e4567-e89b-12d3-a456-426655440000",
            "transactionAmount": {
                "amount": "2000.00",
                "currencyCode": "036"
            }
        },
        {
            "effectiveDate": "2022-01-01",
            "postingTime": "02431772",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "Rent",
            "transactionId": "123e4567-e89b-12d3-a456-426655440013",
            "transactionAmount": {
                "amount": "-200.00",
                "currencyCode": "036"
            }
        },
        {
            "effectiveDate": "2022-01-19",
            "postingTime": "02321387",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "Amazon AU Sydney South Ns Aus Card Xx00045",
            "transactionId": "123e4567-e89b-12d3-a456-426655440010",
            "transactionAmount": {
                "amount": "-130.00",
                "currencyCode": "036"
            }
        },
        {
            "effectiveDate": "2022-01-19",
            "postingTime": "02334143",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "Amazon AU Sydney South Ns Aus Card Xx00045",
            "transactionId": "123e4567-e89b-12d3-a456-426655440011",
            "transactionAmount": {
                "amount": "-210.00",
                "currencyCode": "036"
            }
        },
        {
            "effectiveDate": "2022-01-19",
            "postingTime": "02351477",
            "cardToken": "15004430000000045",
            "accountNbr": "00000000004",
            "product": "Mastercard Prepaid",
            "transDescription": "API DEBIT ACCOUNT TRANS. REV.",
            "transactionId": "123e4567-e89b-12d3-a456-426655440012",
            "transactionAmount": {
                "amount": "210.00",
                "currencyCode": "036"
            }
        }
    ]
}`,
};
