import styled from 'styled-components/macro';
import { FileCopy, Information } from 'styled-icons/remix-line';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  @media (min-width: 768px) {
    width: 500px;
  }
`;

export const StyledLabel = styled.label`
  margin-bottom: 5px;
`;

export const StyledButton = styled.button`
  background-color: #070621;
  color: white;
  font-size: 16px;
  letter-spacing: 0.2em;
  font-weight: 900;
  padding: 10px 10px;
  margin: 10px 0;
`;

export const StyledLink = styled.button`
  background-color: transparent;
  color: var(--link-color, inherit);
  font-weight: var(--link-font-weight, inherit);
  text-decoration: var(--link-text-decoration, inherit);
  border: none;
  margin-left: 5px;
`;

export const SuccessText = styled.h5`
  color: limegreen;
  margin-bottom: 20px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TokenText = styled.p`
  color: ${({ active }) => (active ? 'black' : '#d0d0d0')};
  font-size: 20px;
  margin: 0;
  word-break: break-all;
  white-space: normal;
  width: 100%;
  @media (min-width: 768px) {
    width: 80%;
  }
`;

export const CopyIcon = styled(FileCopy)`
  width: 30px;
  color: ${({ active }) => (active ? 'black' : '#d0d0d0')};
`;

export const InfoIcon = styled(Information)`
  width: 18px;
  color: ${({ active }) => (active ? 'black' : 'red')};
  vertical-align: text-after-edge;
`;

export const TokenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  border-radius: 5px;
  border: 1px solid ${({ active }) => (active ? 'black' : '#d0d0d0')};
  margin: 10px 0;
  padding: 5px;
  cursor: pointer;
`;

export const Layout = styled.div`
  width: 90%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-top: 140px;
`;

export const AuthLayout = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: calc(100vh - 140px);
`;

export const ApiDocumentLayout = styled.div`
  height: 100vh;
  display: block;
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
`;

export const ApiDocumentBody = styled.body`
  padding: 0;
  margin: 0;
`;

export const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 246px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  border-radius: 5px;
  border: 1px solid ${({ active }) => (active ? 'black' : '#d0d0d0')};
  margin: 10px;
  padding: 5px;
  cursor: pointer;
`;

export const NameSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HomeContainer = styled.div`
  width: 100%;
  margin: 110px auto;
  @media (min-width: 500px) {
    max-width: 800px;
  }
  @media (min-width: 1200px) {
    margin-left: 350px;
  }
  @media (min-width: 1340px) {
    margin: 110px auto;
  }
`;
export const StyledHeader = styled.h3`
  margin: 0;
  font-family: 'CircularStdBlack', sans-serif;
`;

export const StyledHeaderText = styled.p`
  color: ${({ active }) => (active ? 'black' : '#d0d0d0')};
  background-color: ${({ background }) =>
    background ? background : '#d0d0d0'};
  margin: 20px 0;
  font-size: 18px;
`;

export const StyledFooterText = styled.p`
  color: ${({ active }) => (active ? 'black' : '#d0d0d0')};
  background-color: ${({ background }) =>
    background ? background : '#d0d0d0'};
  margin: 20px 0;
  font-size: 18px;
`;

export const StyledText = styled.p`
  margin: 20px 0;
  font-size: 18px;
`;

export const EndpointText = styled.div`
  margin: 10px 0;
  font-size: 18px;
  word-wrap: break-word;
`;

export const StyledCode = styled.pre`
  display: block;
  color: palevioletred;
  max-width: 800px;
`;

export const RESTType = styled.p`
  color: white;
  display: inline;
  margin: 0 10px;
  background-color: ${({ type }) =>
    type === 'post' ? '#4ACB90' : type === 'put' ? '#FBA12F' : '#61AFFD'};
  font-weight: bold;
  font-size: 18px;
  padding-bottom: 0 !important;
`;

export const HelperText = styled.p`
  margin: 20px 0;
  font-size: 18px;
  font-weight: bold;
  font-family: 'CircularStdBlack', sans-serif;
`;
export const HelperTextBlue = styled.p`
  color: #61affd;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
`;

export const HelperTextBlueContainer = styled.div`
  border-top: 1px solid #61affd;
  border-bottom: 1px solid #61affd;
  margin: 20px 0;
`;

export const HelperTextBasicContainer = styled.div`
  margin: 20px 0;
`;

export const MessageTextFlexContainer = styled.div`
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

export const StyledSpanBold = styled.span`
  font-weight: 900;
  font-size: 1.2rem;
  text-transform: lowercase;
  text-underline-style: double;
`;
