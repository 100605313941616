import styled from 'styled-components/macro';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

export const StyledHighlighter = styled(SyntaxHighlighter)`
  font-size: 0.8rem;
  @media (max-width: 1200px) {
    font-size: 1rem;
  }
`;
export const WalkthroughContainer = styled.div`
  padding: 20px 0;
`;
export const ItalicText = styled.p`
  font-size: 12px;
  font-style: italic;
`;
