import {
  EndpointText,
  HelperText,
  HelperTextBlue,
  HelperTextBlueContainer,
  RESTType,
} from '../../app.styles';
import { Section } from './Landing.styles';
import { Walkthrough } from '../Walkthrough/Walkthrough';
import { WalkthroughTwo } from '../Walkthrough/Walkthrough2';
import { AppendixText } from './Landing.styles';

export const EndpointsSection = () => {
  const baseUrl = process.env.REACT_APP_API_DOC_ENDPOINT_BASE_ROUTE;

  return (
    <>
      <Section id={`get/v1/customer`}>
        <HelperText>
          {`2. Once customer is created: 
         `}
        </HelperText>
        <EndpointText>
          Get customer details
          <RESTType type="get" id={`get/v1/customer`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getCustomerDetails`}
          >
            {`/v1/customer/{customerNumber}`}
          </a>
        </EndpointText>
        <EndpointText>
          Update customer details
          <RESTType type="put" id={`/v1/customer/{customerNumber}`}>
            PUT
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/updateCustomer`}
          >{`/v1/customer/{customerNumber}`}</a>
        </EndpointText>
        <EndpointText>
          Create an address
          <RESTType type="post" id={`/v1/customer/address/{customerNumber}`}>
            POST
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/createCustomerOtherAddress`}
          >
            {`/v1/customer/address/{customerNumber}`}
          </a>
        </EndpointText>
        <EndpointText>
          Update an address
          <RESTType type="put" id={`/v1/customer/address/{customerNumber}`}>
            PUT
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/updateCustomerOtherAddress`}
          >
            {`/v1/customer/address/{customerNumber}`}
          </a>
        </EndpointText>
      </Section>
      <Section id={`/v1/product/list`}>
        <HelperText>3. List Products:</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getProductList`}
          >
            {`/v1/product/list`}
          </a>
        </EndpointText>
      </Section>
      <Section id={`/v1/product/{cardProduct}/application/debitOrprepaid/`}>
        <HelperText>4. Apply for a card Product</HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/createCardDebNormalApplication`}
          >
            {`/v1/product/{cardProduct}/application/debitOrprepaid/`}
          </a>
        </EndpointText>
        <HelperText>cardMasked cardToken account</HelperText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/activate`}>
        <HelperTextBlueContainer>
          <HelperTextBlue>
            For the following endpoints, recommend using cardToken instead of a
            clear card number
          </HelperTextBlue>
        </HelperTextBlueContainer>
        <HelperText>5. Activate Card</HelperText>
        <EndpointText>
          <RESTType type="put">PUT</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/activateCard`}
          >{`/v1/card/{cardNumber}/activate`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/system/rsa`}>
        <HelperText>6. Set a PIN (optional)</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getPublicRSAKeyX509`}
          >{`/v1/system/rsa`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="put" id={`/v1/card/{cardNumber}/pin`}>
            PUT
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/setPinCard`}
          >{`/v1/card/{cardNumber}/pin`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/services`}>
        <HelperText>7. List the services allowed on the card</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getCardServices`}
          >{`/v1/card/{cardNumber}/services`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/block`}>
        <HelperText>8. Temporary block / unblock a card</HelperText>
        <EndpointText>
          <RESTType type="put">PUT</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/cardTempBlockUpdate`}
          >{`/v1/card/{cardNumber}/block`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/services`}>
        <HelperText>9. Overwrite service setup at card level</HelperText>
        <EndpointText>
          <RESTType type="put">PUT</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/putCardServices`}
          >{`/v1/card/{cardNumber}/services`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/reissue`}>
        <HelperText>10. Reissue the same card</HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/reissueCard`}
          >{`/v1/card/{cardNumber}/reissue`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardTokenNbr}/cardRSAEncrypted`}>
        <HelperText>
          11. Request clear card and CVV2 encrypted under a provided public key
        </HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getCardRSAEnc`}
          >{`/v1/card/{cardTokenNbr}/cardRSAEncrypted`}</a>
        </EndpointText>
      </Section>

      <Section id={`/v1/card/{cardNumber}/account/list`}>
        <HelperText>12. List of accounts a card can transact on</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getCardAccounts`}
          >{`/v1/card/{cardNumber}/account/list`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/cancel`}>
        <HelperText>13. Cancel a card (i.e. stolen / lost)</HelperText>
        <EndpointText>
          <RESTType type="put">PUT</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/cancelCard`}
          >{`/v1/card/{cardNumber}/cancel`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/card/{cardNumber}/replace`}>
        <HelperText>14. Replace the card</HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/replaceCard`}
          >{`/v1/card/{cardNumber}/replace`}</a>
        </EndpointText>
      </Section>

      <Section
        id={`/v1/account/{account}/application/additionalCard/{customerNumber}`}
      >
        <HelperText>15. Apply for an additional card on the account</HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/createAdditionalCardOnAccount`}
          >
            {`/v1/account/{account}/application/additionalCard/{customerNumber}`}
          </a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/card/list`}>
        <HelperText>16. List of accounts a card can transact on</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getAccountCards`}
          >{`/v1/account/{account}/card/list`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/details`}>
        <HelperText>17. Get the account details</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getAccountDetails`}
          >{`/v1/account/{account}/details`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/transaction/credit`}>
        <HelperText>18. Post a credit amount</HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/creditAmountInAccount`}
          >{`/v1/account/{account}/transaction/credit`}</a>
        </EndpointText>
      </Section>

      <Section id={`/v1/account/{account}/transaction/debit`}>
        <HelperText>19. Post a debit amount</HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/debitAmountInAccount`}
          >{`/v1/account/{account}/transaction/debit`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/transaction/transfer/{toAccount}`}>
        <HelperText>
          20. Post a transfer from one account to another account. The account
          can be for the same customer (i.e. another currency) or a different
          customer. Balances of the accounts need to be in the system.
        </HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/transferAmountBetweenAccounts`}
          >
            {`/v1/account/{account}/transaction/transfer/{toAccount}`}
          </a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/transaction/reversal`}>
        <HelperText>
          21. Post a reversal of a transaction (credit, debit or transfer)
        </HelperText>
        <EndpointText>
          <RESTType type="post">POST</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/postAuthorisationReversal`}
          >{`/v1/account/{account}/transaction/reversal`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/balance`}>
        <HelperText>22. Get the balance of an account</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getAccountBalance`}
          >{`/v1/account/{account}/balance`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/transaction/list`}>
        <HelperText>
          23. Get the list of transactions posted to an account
        </HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getFinancialTransactionsOnAccount`}
          >{`/v1/account/{account}/transaction/list`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/account/{account}/transaction/{transactionId}`}>
        <HelperText>24. Get the detail of one transaction</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            id="transactionFlowExample"
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getFinancialTransactionsbyId`}
          >{`/v1/account/{account}/transaction/{transactionId}`}</a>
        </EndpointText>
      </Section>
      <Walkthrough />
      <WalkthroughTwo />
      <Section id={`/v1/management/customer/{customerNumber}`}>
        <HelperText>
          The request of the following endpoints should come from your
          administrative role:
        </HelperText>
        <HelperText>1. Get customer internal attributes</HelperText>
        <EndpointText>
          <RESTType type="get">GET</RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getManagedCustomerProperties`}
          >{`/v1/management/customer/{customerNumber}`}</a>
        </EndpointText>
        <HelperText>2. Update customer internal attributes</HelperText>
        <EndpointText>
          <RESTType type="put" id={`/v1/management/customer/{customerNumber}`}>
            PUT
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/updateManagedCustomerProperties`}
          >{`/v1/management/customer/{customerNumber}`}</a>
        </EndpointText>
      </Section>
      <Section id={`/v1/system/codes/addressType`}>
        <HelperText>
          A code specified in the documentation referring to an endpoint
          /v1/system/[element name] will return the description associated with
          each code in that group:
        </HelperText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/addressType`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeAddressType`}
          >{`/v1/system/codes/addressType`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/cancellation`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getCancelCodeList`}
          >{`/v1/system/codes/cancellation`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/cardDespatch`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeCardDespatch`}
          >{`/v1/system/codes/cardDespatch`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/carOwnerCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeCarOwnerCode`}
          >{`/v1/system/codes/carOwnerCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/customerClassCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeCustomerClassCode`}
          >{`/v1/system/codes/customerClassCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/educationAttainment`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeEducationAttainment`}
          >{`/v1/system/codes/educationAttainment`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/feeAcctLocation`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodefeeAcctLocation`}
          >{`/v1/system/codes/feeAcctLocation`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/incomeSourceCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeIncomeSourceCode`}
          >{`/v1/system/codes/incomeSourceCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/issueReason`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeIssueReason`}
          >{`/v1/system/codes/issueReason`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/CountryName`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeCountryName`}
          >{`/v1/system/codes/CountryName`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/isoCurrencyCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeIsoCurrencyCode`}
          >{`/v1/system/codes/isoCurrencyCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/languageCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeLanguageCode`}
          >{`/v1/system/codes/languageCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/mailingCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeMailingCode`}
          >{`/v1/system/codes/mailingCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/maritalStatus`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeMaritalStatus`}
          >{`/v1/system/codes/maritalStatus`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/nationality`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeNationality`}
          >{`/v1/system/codes/nationality`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/occupationCategory`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeOccupationCategory`}
          >{`/v1/system/codes/occupationCategory`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/ownHomeCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeOwnHomeCode`}
          >{`/v1/system/codes/ownHomeCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/pinDespatch`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodePinDespatch`}
          >{`/v1/system/codes/pinDespatch`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/preferredContact`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodePreferredContact`}
          >{`/v1/system/codes/preferredContact`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/reportMethod`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeReportMethod`}
          >{`/v1/system/codes/reportMethod`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/state`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeState`}
          >{`/v1/system/codes/state`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/taxCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeTaxCode`}
          >{`/v1/system/codes/taxCode`}</a>
        </EndpointText>
        <EndpointText>
          <RESTType type="get" id={`/v1/system/codes/vipCode`}>
            GET
          </RESTType>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${baseUrl}/getSystemCodeVipCode`}
          >{`/v1/system/codes/vipCode`}</a>
        </EndpointText>
      </Section>

      <HelperText id="appendix">Appendix:</HelperText>
      <AppendixText>
        John Doe "customerNumber": "0000000078" "account": "00000000002",
        "cardToken": "15004430000000029"
      </AppendixText>
      <AppendixText>
        Jane Doe "customerNumber": "0000000094", "account": "00000000004",
        "cardToken": "15004430000000045"
      </AppendixText>
      <AppendixText>
        James Smith "customerNumber": "0000000116", "account": "00000000005",
        "cardToken": "15004430000000052"
      </AppendixText>
      <AppendixText>
        Johanna Smith “customerNumber”: “0000000132”, "account": "00000000006",
        "cardToken": "15004430000000060"
      </AppendixText>
      <AppendixText>
        Mary McMahon “customerNumber”: “0000000159”, "account": "00000000007",
        "cardToken": "15004430000000078"
      </AppendixText>
      <AppendixText>
        Micheal McMahon "customerNumber":"0000000175", "account": "00000000008",
        "cardToken": "15004430000000086"
      </AppendixText>
      <AppendixText>
        Richard Public “customerNumber”: “0000000191”, "account": "00000000009",
        "cardToken": "15004430000000094",
      </AppendixText>
      <AppendixText>
        Kelly Public “customerNumber”: “0000000213”, "account": "00000000010",
        "cardToken": "15004430000000102"
      </AppendixText>
      <AppendixText>
        Bob Citizen “customerNumber”: “0000000232”, "account": "00000000011" ,
        "cardToken": "15004430000000110"
      </AppendixText>
      <AppendixText>
        Betty Citizen “customerNumber”: “0000000256”, "account": "00000000012",
        "cardToken": "15004430000000128"
      </AppendixText>
    </>
  );
};