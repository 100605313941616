import isEmpty from 'lodash/isEmpty';
export const handleErrors = (error, errors, setErrors) => {
  const errEmail = [];
  const errPassword = [];
  const errOther = [];

  if (error.includes('USERNAME')) {
    if (error.includes(errorResponses.email.one)) {
      errEmail.push(errorResponses.email.oneError);
    }
  }
  if (error.includes('username')) {
    if (error.includes(errorResponses.email.two)) {
      errEmail.push(errorResponses.email.twoError);
    }
  }
  if (error.includes('Username')) {
    if (error.includes(errorResponses.email.five)) {
      errEmail.push(errorResponses.email.fiveError);
    }
  }
  if (error.includes('User')) {
    if (error.includes(errorResponses.email.three)) {
      errEmail.push(errorResponses.email.threeError);
    }
    if (error.includes(errorResponses.email.four)) {
      errEmail.push(errorResponses.email.fourError);
    }
  }
  if (error.includes(errorResponses.password.one)) {
    errPassword.push(errorResponses.password.oneError);
  }
  if (error.includes('Username')) {
  }
  if (error.includes('account')) {
  }
  if (error.includes('password')) {
  }
  if (error.includes('Password')) {
  }
  if (isEmpty(errEmail) && isEmpty(errPassword) && !isEmpty(error)) {
    errOther.push(error);
  }
  setErrors({
    email: errEmail.join(' '),
    password: errPassword.join(' '),
    other: errOther.join(' '),
  });
};

const errorResponses = {
  password: {
    one: 'Custom auth lambda trigger is not configured for the user pool.',
    oneError: 'Password must not be empty ',
  },
  email: {
    one: 'Missing required parameter USERNAME',
    oneError: 'Email must not be empty.',
    two: 'Incorrect username or password',
    twoError: 'Incorrect username or password',
    three: 'User is not confirmed',
    threeError: 'User is not confirmed, please wait for approval',
    four: 'User is disabled',
    fourError: 'User is disabled',
    five: 'Username cannot be empty',
    fiveError: 'Email must not be empty',
  },
};
