import { useState } from 'react';
import {
  ButtonContainer,
  CopyIcon,
  HelperText,
  HomeContainer,
  StyledButton,
  StyledHeader,
  TokenContainer,
  TokenText,
  StyledText,
  EndpointText,
  RESTType,
} from '../../app.styles';
import { TestCustomerSection } from '../../components/Landing/NameSection';
import NameImg from '../../assets/NameImg.png';
import { EndpointsSection } from '../../components/Landing/EndpointsSection';
import { Menu } from '../../components/Menu/Menu';
import {
  LandingContainer,
  Section,
} from '../../components/Landing/Landing.styles';
import { ROUTES } from '../../AppRoutes';

const apiToken = process.env.REACT_APP_SWAGGER_API_TOKEN;
const changeApiEndPoint = process.env.REACT_APP_CHANGE_API_PUBLIC_ENDPOINT;

export const Landing = () => {
  const [token, setToken] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);

  const generateToken = () => {
    setToken('Bearer ' + apiToken);
  };
  const copyToClipboard = (item) => {
    navigator.clipboard.writeText(item).then(
      function () {
        alert('Copied successfully');
      },
      function (err) {
        console.error('Async: Could not copy text: ', err);
      }
    );
  };
  const baseUrl = process.env.REACT_APP_API_DOC_ENDPOINT_BASE_ROUTE;

  return (
    <>
      <LandingContainer>
        <Menu />
        <HomeContainer>
          <StyledHeader>Change Financial API access</StyledHeader>
          <Section>
            <StyledText>
              When accessing the Change Financial Sandbox for Vertexon, the
              following steps will give the consumer of the APIs the purpose of
              each endpoint and, its technical specification. The aim is to
              facilitate the system-to-system integration to Change Financial
              platform.
            </StyledText>

            <StyledText>
              Refer to the Open API documentation here:{' '}
              <a
                href={ROUTES.HREF.API_DOC_HTML}
                target="_blank"
                rel="noopener noreferrer"
              >
                <StyledButton type="button"> Click to open docs</StyledButton>
              </a>
            </StyledText>
            <HelperText>Endpoint :</HelperText>
            <TokenContainer
              active={activeIndex === 1}
              onClick={() => copyToClipboard(changeApiEndPoint)}
              onMouseOver={() => setActiveIndex(1)}
              onMouseOut={() => setActiveIndex(null)}
            >
              <TokenText active={activeIndex === 1}>
                {changeApiEndPoint}
              </TokenText>
              <CopyIcon active={activeIndex === 1} />
            </TokenContainer>
            <StyledText>
              The examples provided in the swagger documentation are a guideline
              only. Each endpoint requires an Authorization header with Bearer
              followed by the API key /Token provided.
            </StyledText>
            <ButtonContainer>
              <StyledButton
                onClick={generateToken}
                type="button"
                className="ninja-forms-field nf-element"
              >
                GENERATE TOKEN
              </StyledButton>
            </ButtonContainer>
            {token && (
              <>
                <HelperText>Token :</HelperText>
                <TokenContainer
                  active={activeIndex === 2}
                  onClick={() => copyToClipboard(token)}
                  onMouseOver={() => setActiveIndex(2)}
                  onMouseOut={() => setActiveIndex(null)}
                >
                  <TokenText active={activeIndex === 2}>{token}</TokenText>
                  <CopyIcon active={activeIndex === 2} />
                </TokenContainer>
              </>
            )}
          </Section>
          <Section id={`post/v1/customer`}>
            <HelperText>
              1. Create customer Please ensure you enter one of the sample
              customers mentioned in the Sandbox page:
            </HelperText>
            <img src={NameImg} alt="Name Example" />
            <StyledText>
              Ref. to the Appendix for a complete set of samples prepared
              specially for your journey.
            </StyledText>
            <EndpointText>
              <RESTType type="post">POST</RESTType>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${baseUrl}/createCustomer`}
              >
                /v1/customer
              </a>
            </EndpointText>
            <HelperText>Test customers :</HelperText>
            <TestCustomerSection
              setActiveIndex={setActiveIndex}
              activeIndex={activeIndex}
              copyToClipboard={copyToClipboard}
            />
          </Section>

          <EndpointsSection />
        </HomeContainer>
      </LandingContainer>
    </>
  );
};
