import styled from 'styled-components/macro';

export const headerFooterBackground = '#111b37';

export const FooterContainer = styled.footer`
  background-color: ${headerFooterBackground};
  height: 70px;
  width: 100%;
  position: sticky;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  justify-content: space-between;
`;

export const FooterLeft = styled.div``;
export const FooterMid = styled.div``;
export const FooterRight = styled.div``;
