export const awsConfig = {
  Auth: {
    region: process.env.REACT_APP_REGION, // (required) - Amazon Cognito Region
    userPoolId: process.env.REACT_APP_USER_POOL_ID, // (required) - Amazon Cognito User Pool ID
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID, // (required) - change-dev-website in SB Cognito settings.
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
    },
  },
};
