import {
  CopyIcon,
  NameContainer,
  NameSection,
  TokenText,
} from '../../app.styles';

export const TestCustomerSection = ({
  activeIndex,
  copyToClipboard,
  setActiveIndex,
}) => {
  return (
    <NameSection>
      <NameContainer
        active={activeIndex === 3}
        onClick={() => copyToClipboard('John Doe')}
        onMouseOver={() => setActiveIndex(3)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 3}>John Doe </TokenText>
        <CopyIcon active={activeIndex === 3} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 4}
        onClick={() => copyToClipboard('Jane Doe')}
        onMouseOver={() => setActiveIndex(4)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 4}>Jane Doe </TokenText>
        <CopyIcon active={activeIndex === 4} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 5}
        onClick={() => copyToClipboard('James Smith')}
        onMouseOver={() => setActiveIndex(5)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 5}>James Smith </TokenText>
        <CopyIcon active={activeIndex === 5} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 6}
        onClick={() => copyToClipboard('Johanna Smith')}
        onMouseOver={() => setActiveIndex(6)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 6}>Johanna Smith </TokenText>
        <CopyIcon active={activeIndex === 6} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 7}
        onClick={() => copyToClipboard('Mary McMahon')}
        onMouseOver={() => setActiveIndex(7)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 7}>Mary McMahon </TokenText>
        <CopyIcon active={activeIndex === 7} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 8}
        onClick={() => copyToClipboard('Michael McMahon')}
        onMouseOver={() => setActiveIndex(8)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 8}>Michael McMahon </TokenText>
        <CopyIcon active={activeIndex === 8} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 9}
        onClick={() => copyToClipboard('Richard Public')}
        onMouseOver={() => setActiveIndex(9)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 9}>Richard Public </TokenText>
        <CopyIcon active={activeIndex === 9} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 10}
        onClick={() => copyToClipboard('Kelly Public')}
        onMouseOver={() => setActiveIndex(10)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 10}>Kelly Public </TokenText>
        <CopyIcon active={activeIndex === 10} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 11}
        onClick={() => copyToClipboard('Bob Citizen')}
        onMouseOver={() => setActiveIndex(11)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 11}>Bob Citizen </TokenText>
        <CopyIcon active={activeIndex === 11} />
      </NameContainer>
      <NameContainer
        active={activeIndex === 12}
        onClick={() => copyToClipboard('Betty Citizen')}
        onMouseOver={() => setActiveIndex(12)}
        onMouseOut={() => setActiveIndex(null)}
      >
        <TokenText active={activeIndex === 12}>Betty Citizen </TokenText>
        <CopyIcon active={activeIndex === 12} />
      </NameContainer>
    </NameSection>
  );
};
