import { ROUTES } from '../../AppRoutes';
import {
  HomeIcon,
  MenuContainer,
  MenuHeaderContainer,
  StyledTextAnchor,
} from './Menu.styles';
import { menuItems } from '../../constants/menuConstants';
import { MenuSection } from './MenuSection';

export const Menu = () => {
  return (
    <MenuContainer>
      <MenuHeaderContainer>
        <StyledTextAnchor href={ROUTES.EXTERNAL.CHANGE_SITE_URL}>
          <HomeIcon /> Return to home
        </StyledTextAnchor>
      </MenuHeaderContainer>
      {menuItems.map((menuSection) => (
        <MenuSection menuSection={menuSection} key={menuSection.header} />
      ))}
    </MenuContainer>
  );
};
