import {
  MenuHeaderContainer,
  MenuItemContainer,
  StyledHeader,
  StyledTextAnchor,
} from './Menu.styles';

export const MenuSection = ({ menuSection }) => {
  return (
    <>
      <MenuHeaderContainer>
        <StyledHeader>{menuSection.header}</StyledHeader>
      </MenuHeaderContainer>
      {menuSection.items.map((menuItem, idx) => (
        <MenuItemContainer key={menuItem.href + idx}>
          <StyledTextAnchor href={menuItem.href}>
            {menuItem.text}
          </StyledTextAnchor>
        </MenuItemContainer>
      ))}
    </>
  );
};
