import PhoneInput from 'react-phone-input-2';
import { ErrorAsterisks, ErrorLabel, StyledLabel } from './Input';

export const PhoneNumber = ({ errors, phoneNumber, setPhoneNumber }) => {
  return (
    <>
      <StyledLabel htmlFor="phoneNumber">
        Phone Number<ErrorAsterisks>*</ErrorAsterisks>
      </StyledLabel>
      <PhoneInput
        country={'au'}
        autoFormat={false}
        inputStyle={{
          width: '100%',
          color: '#77889a',
          border: '1px solid #d0d0d0',
          fontSize: '16px',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
        placeholder="Mobile e.g. +61xxxxxxxxx"
        value={phoneNumber}
        onChange={(phone) => {
          setPhoneNumber(phone);
        }}
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true,
        }}
      />
      <ErrorLabel>{errors?.phoneNumber || ''}</ErrorLabel>
    </>
  );
};
