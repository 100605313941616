import styled from 'styled-components/macro';

export const HeaderContainer = styled.div`
  background-color: #111b37;
  height: 70px;
  width: 100%;
  top: 0px;
  position: sticky;
  z-index: 10;
  display: flex;
  align-items: center;
  padding: 30px 20px;
  justify-content: space-between;
`;

export const LogoImage = styled.img`
  width: 164px;
`;

export const HeaderLeft = styled.div``;
export const HeaderMid = styled.div``;
export const HeaderRight = styled.div``;
