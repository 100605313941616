import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Landing } from './routes/Protected/Landing';
import { Signup } from './routes/Public/Signup';
import { Login } from './routes/Public/Login';
import { ForgotPassword } from './routes/Public/Password/ForgotPassword';
import { ConfirmPasswordReset } from './routes/Public/Password/ConfirmPasswordReset';
import { ResetPassword } from './routes/Public/Password/ResetPassword';
import { Layout, StyledHeader, StyledText } from './app.styles';
import { StyledView } from './styles/aws-styled';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';

export const ROUTES = {
  HOME: '/',
  LANDING: '/',
  AUTH: {
    FORGOT: '/forgot',
    RESET: '/reset',
    SIGN_UP: '/signup',
    LOG_IN: '/login',
    CONFIRM: '/confirm',
  },
  // DOCS: '/docs',
  HREF: {
    API_DOC_HTML: '/open-api',
  },
  NO_MATCH: '/*',
  EXTERNAL: {
    CHANGE_SITE_URL: process.env.REACT_APP_CHANGE_MAIN_SITE_URL,
    PRIVACY_POLICY: process.env.REACT_APP_CHANGE_PRIVACY_POLICY_URL,
  },
};

const AUTH_ROUTES = Object.values(ROUTES.AUTH);

function Home() {
  const { authStatus, user } = useAuthenticator((context) => [
    context.user,
    context.route,
    context.authStatus,
  ]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(async () => {
    const page = location.pathname;
    const authUser = await Auth.currentAuthenticatedUser()
      .then((data) => data)
      .catch((error) => {
        console.log('error', error);
        return false;
      });
    const userLoggedIn = user || authUser;

    // If it is an auth route it will still work but all others will fail without a valid user.
    if (!userLoggedIn && !AUTH_ROUTES.includes(page)) {
      return navigate('/login', { replace: true });
    }
    return navigate(page, { replace: true });
  }, [authStatus, user, location.pathname, navigate]);

  return (
    <>
      <Header pageName={location.pathname} />
      <Outlet />
      <Footer />
    </>
  );
}

export function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />}>
          <Route path={ROUTES.LANDING} element={<Landing />} />
          <Route path={ROUTES.AUTH.SIGN_UP} element={<Signup />} />
          <Route path={ROUTES.AUTH.LOG_IN} element={<Login />} />
          <Route
            path={ROUTES.AUTH.CONFIRM}
            element={<ConfirmPasswordReset />}
          />
          <Route path={ROUTES.AUTH.FORGOT} element={<ForgotPassword />} />
          <Route path={ROUTES.AUTH.RESET} element={<ResetPassword />} />
          <Route path={ROUTES.NO_MATCH} element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

function NoMatch() {
  return (
    <>
      <Layout>
        <StyledHeader> 404 </StyledHeader>
        <StyledView>
          <StyledText> Sorry, no page at this location </StyledText>
        </StyledView>
      </Layout>
    </>
  );
}
