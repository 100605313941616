import styled from 'styled-components/macro';

export const AppendixText = styled.p`
  margin: 10px 0;
`;

export const LandingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 20px;
  @media (min-width: 800px) {
    margin: 0;
  }
`;

export const Section = styled.section`
  margin: 0;
  padding-top: 30px;
`;
