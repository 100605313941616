import isEmpty from 'lodash/isEmpty';

export const handleErrors = (error, errors, setErrors) => {
  const errEmail = [];
  const errPassword = [];
  const errConfirmPassword = [];
  const errCountry = [];
  const errCompany = [];
  const errPhoneNumber = [];
  const errName = [];
  const errFirstName = [];
  const errLastName = [];
  const errState = [];
  const errOther = [];

  if (error.includes('username')) {
    if (error.includes(errorResponses.email.one)) {
      errEmail.push(errorResponses.email.oneError);
    }
    if (error.includes(errorResponses.email.two)) {
      errEmail.push(errorResponses.email.twoError);
    }
    if (error.includes(errorResponses.email.three)) {
      errEmail.push(errorResponses.email.threeError);
    }
  }

  if (error.includes('Username')) {
    if (error.includes(errorResponses.email.four)) {
      errEmail.push(errorResponses.email.fourError);
    }
    if (error.includes(errorResponses.email.six)) {
      errEmail.push(errorResponses.email.sixError);
    }
  }
  if (error.includes('account')) {
    if (error.includes(errorResponses.email.five)) {
      errEmail.push(errorResponses.email.fiveError);
    }
  }
  if (error.includes('password')) {
    if (error.includes(errorResponses.password.one)) {
      errPassword.push(errorResponses.password.oneError);
    }
  }
  if (error.includes('Password')) {
    if (error.includes(errorResponses.password.two)) {
      errPassword.push(errorResponses.password.twoError);
    }
    if (error.includes(errorResponses.password.three)) {
      errPassword.push(errorResponses.password.threeError);
    }
    if (error.includes(errorResponses.password.four)) {
      errPassword.push(errorResponses.password.fourError);
    }
  }
  if (error.includes('confirmPassword')) {
    if (error.includes(errorResponses.confirmPassword.one)) {
      errConfirmPassword.push(errorResponses.confirmPassword.oneError);
    }
  }
  if (error.includes('name')) {
    if (error.includes(errorResponses.name.one)) {
      errName.push(errorResponses.name.oneError);
    }
  }
  if (error.includes('firstName')) {
    if (error.includes(errorResponses.firstName.one)) {
      errFirstName.push(errorResponses.firstName.oneError);
    }
  }
  if (error.includes('lastName')) {
    if (error.includes(errorResponses.lastName.one)) {
      errLastName.push(errorResponses.lastName.oneError);
    }
  }
  if (error.includes('zoneinfo')) {
    if (error.includes(errorResponses.country.one)) {
      errCountry.push(errorResponses.country.oneError);
    }
  }
  if (error.includes('profile')) {
    if (error.includes(errorResponses.company.one)) {
      errCompany.push(errorResponses.company.oneError);
    }
  }
  if (error.includes('phone_number')) {
    if (error.includes(errorResponses.phone_number.one)) {
      errPhoneNumber.push(errorResponses.phone_number.oneError);
    }
  }
  if (error.includes('phone number')) {
    if (error.includes(errorResponses.phone_number.two)) {
      errPhoneNumber.push(errorResponses.phone_number.twoError);
    }
  }

  if (error.includes('locale')) {
    if (error.includes(errorResponses.locale.one)) {
      errState.push(errorResponses.locale.oneError);
    }
  }
  if (
    isEmpty(errEmail) &&
    isEmpty(errPassword) &&
    isEmpty(errConfirmPassword) &&
    isEmpty(errCountry) &&
    isEmpty(errCompany) &&
    isEmpty(errPhoneNumber) &&
    isEmpty(errName) &&
    isEmpty(errFirstName) &&
    isEmpty(errLastName) &&
    isEmpty(errState)
  ) {
    errOther.push(error);
  }

  setErrors({
    email: errEmail.join(' '),
    password: errPassword.join(' '),
    confirmPassword: errConfirmPassword.join(' '),
    country: errCountry.join(' '),
    company: errCompany.join(' '),
    phoneNumber: errPhoneNumber.join(' '),
    name: errName.join(' '),
    firstName: errFirstName.join(' '),
    lastName: errLastName.join(' '),
    state: errState.join(' '),
    other: errOther.join(' '),
  });
};

const errorResponses = {
  password: {
    one: "'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\\\S]+.*[\\\\S]+$",
    oneError: 'Invalid password',
    two: 'Password did not conform with policy: Password must have numeric characters',
    twoError: 'Password must have numeric characters',
    three:
      'Password did not conform with policy: Password must have symbol characters',
    threeError: 'Password must have symbol characters',
    four: 'Password cannot be empty',
    fourError: 'Password must not be empty',
  },
  confirmPassword: {
    one: "'confirmPassword' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\\\S]+.*[\\\\S]+$",
    oneError: 'Invalid password',
    two: 'Password did not conform with policy: Password must have numeric characters',
    twoError: 'Password must have numeric characters',
    three:
      'Password did not conform with policy: Password must have symbol characters',
    threeError: 'Password must have symbol characters',
  },
  email: {
    one: "'username' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\\\p{L}\\\\p{M}\\\\p{S}\\\\p{N}\\\\p{P}]+",
    oneError: 'Must be a valid email',
    two: "'username' failed to satisfy constraint: Member must have length greater than or equal to 1",
    twoError: 'Email must not be empty.',
    three: 'Username should be an email"',
    threeError: 'Must be a valid email',
    four: 'Username should be an email',
    fourError: 'Username should be an email',
    five: 'An account with the given email already exists.',
    fiveError: 'An account with this email already exists',
    six: 'Username cannot be empty',
    sixError: 'Email must not be empty',
  },
  name: {
    one: 'name: The attribute is required',
    oneError: 'Name is required',
  },
  firstName: {
    one: 'firstName: The attribute is required',
    oneError: 'First Name is required',
  },
  lastName: {
    one: 'lastName: The attribute is required',
    oneError: 'Last Name is required',
  },
  country: {
    one: 'zoneinfo: The attribute is required',
    oneError: 'Country is required',
  },
  locale: {
    one: 'locale: The attribute is required',
    oneError: 'State is required',
  },
  company: {
    one: 'profile: The attribute is required',
    oneError: 'Company name is required',
  },
  phone_number: {
    one: 'The attribute is required',
    oneError: 'Phone Number is required',
    two: 'Invalid phone number format',
    twoError: 'Invalid phone number format',
  },
};
